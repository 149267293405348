import { faChartBar, faClipboardList, faClock, faCode, faVideo } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"

export default class CorsoAngularNgrx extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_fundamentals: file(relativePath: { eq: "courses/courses-logos/angular-fundamentals.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/angular-fundamentals/carousel/01.jpeg" }) { ...imgTrainingBig },
            carouselIntro2: file(relativePath: { eq: "courses/angular-fundamentals/carousel/02.jpeg" }) { ...imgTrainingBig },
            carouselIntro3: file(relativePath: { eq: "courses/angular-fundamentals/carousel/03.jpeg" }) { ...imgTrainingBig },

            review_1: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_daniela_ferrante.png" }) { ...imgTrainingBig },
            review_2: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_marco_brunet.png" }) { ...imgTrainingBig },
            review_3: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_zack_nero.png" }) { ...imgTrainingBig },
            review_4: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_moreno_mazzocchetti.png" }) { ...imgTrainingBig },
            review_5: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_gennaro_zaro.png" }) { ...imgTrainingBig },
            review_6: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_claudia_valente.png" }) { ...imgTrainingBig },
            review_7: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_davide_pizzoli.png" }) { ...imgTrainingBig },
            review_8: file(relativePath: { eq: "courses/courses-reviews/video-angular-fundamentals/review_denis_saporetti.png" }) { ...imgTrainingBig },


            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-ngrx-mode3.png" }) { ...imgMd },


          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#dd0031',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#dd0031',
    separatorColor2: '#c3002f',
    buttonColor: '#dd0031',
    panelBackground1: '#E66161',
    iconColor1: '#666',       // summary
    iconColor2: '#dd0031',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Video Corso Angular Fundamentals in Italiano',
    title: 'ANGULAR <strong>FUNDAMENTALS</strong>',
    shortTitle: '<em>video corso</em><br>ANGULAR <strong>FUNDAMENTALS</strong>',
    subtitle: 'Un video corso sulle fondamenta del framework, pattern e best practice. Include, inoltre, il mini-corso "Javascript ES6" e ...',
    image: 'angular_fundamentals',
    difficulty: 'principiante',
    duration: '18+ ore'
  },
  description: [
    { title: null, text: 'Dopo aver lavorato con grandi clienti internazionali e formato per anni aziende e freelance offline, ho deciso di ascoltare i feedback delle miei studenti e sviluppare un video corso completo, adatto sia ai principianti, che ai developer più esperti che già utilizzano il framework.'},
    { title: 'CHE COS\'È ANGULAR FUNDAMENTALS?', text: 'Angular Fundamentals è un corso intensivo in cui si descrivono le principali funzionalità dell’ultima release di Angular.\n' +
        '<br />' +
        'Moltissimi esempi pratici e casi d’uso tramite il quale potrete non solo apprendere i concetti fondamentali del framework per la creazione di  Single Page Applications ma applicare fin da subito pattern, metodologie e best practice tramite sessioni di teoria, pratica e live-coding.' +
        '<br />' +
        'Inclusi nel pacchetto troverai anche un mini-corso su Javascript ES6 propedeutico a quello Angular e un corso di approfondimento sulla creazione di librerie NPM di componenti e servizi Angular.'
    },
    {
      title: 'A CHI È RIVOLTO?',
      text: `
<br />
<strong>1) BEGINNERS</strong>
<br /><br />  
Per tutti coloro che desiderano utilizzare Angular e creare  Single Page Application tramite un percorso di studio graduale, ben strutturato, che permetta ai neofiti di partire da zero e raggiungere un buon livello in brevissimo tempo. L’unico requisito è la conoscenza di almeno un linguaggio di programmazione.
<br /><br />
<strong>2) ANGULAR DEVELOPERS</strong>
<br /><br />
Per gli sviluppatori che già utilizzano Angular ma non ritengono di farlo nel modo corretto e/o desiderano migliorare la qualità del loro codice applicando best practice, pattern, metodologie e ottimizzando il codice allo scopo di renderlo più performante, leggibile e manutenibile.
    `},
  ],
  metadata: [
    {
      title: 'COSA TROVERAI DENTRO ANGULAR FUNDAMENTALS ?\n',
      text: `
<br />
<strong>Più di 130 video su Angular</strong>
<br />
Nel video corso troverai più di 130 video e 16 ore di lezione suddivise in capitoli, con sessioni di teoria, pratica, live-coding:  sviluppo Single Page App, applicazioni CRUD, Realtime app con Google Firebase e decine di casi d’uso ed esempi pratici.

<br /><br />
<strong>Mini corso "Javascript ES6" in omaggio (altri 26 video)</strong>
<br />
Avrai accesso ad uno speciale mini corso su Javascript ES6, appositamente realizzato per semplificare l’apprendimento di framework front-end come Angular,  Vue o React.

<br /><br />
<strong>Mini corso "Creazione e pubblicazione librerie / UIKIT Angular su NPM" (altri 10 video)</strong>
<br />
Avrai accesso ad uno speciale mini corso nella quale descrivo come realizzare una libreria di componenti e servizi Angular da pubblicare su NPM affinché possa essere utilizzata da più progetti

<br /><br />
<strong>Aggiornamenti</strong>
<br />
L’acquisto include tutti i futuri aggiornamenti, quindi avrete accesso a nuovi video con  argomenti attualmente non inclusi, bug fix e materiale extra (PDF, Slides, Codice)

<br /><br />
<strong>Soddisfatto o Rimborsato</strong>
<br />
Se non sei soddisfatto di quello che hai acquistato, nessun problema. Entro 30 giorni dall’acquisto potrai richiedere il rimborso del 100% del corso 😉
      `
    },
    { title: 'REQUISITI', text: 'Familiarità con almeno un linguaggio di programmazione e, possibilmente, conoscere i concetti fondamentali su cui si basa il paradigma della programmazione ad oggetti. Aver già utilizzato HTML, CSS e Javascript ES6 sicuramente saranno di grande aiuto.' },
  ],
  carousels: {
    main: [
      {img: 'carouselIntro1', desc: 'Nel corso organizzeremo i layout in componenti'},
      {img: 'carouselIntro2', desc: 'Applicazioni stateless e business logic (stato applicativo e side effect) gestiti attraverso appositi servizi'},
      {img: 'carouselIntro3', desc: 'Organizzazione in custom modules: "core", "features" e "shared"'},
    ],
    reviews: [
      {img: 'review_8', desc: ''},
      {img: 'review_1', desc: ''},
      {img: 'review_2', desc: ''},
      {img: 'review_3', desc: ''},
      {img: 'review_4', desc: ''},
      {img: 'review_5', desc: ''},
      {img: 'review_6', desc: ''},
      {img: 'review_7', desc: ''},
    ],  },
  videos: {
    teaser1: 'teYQqexbvUs',
  },
  summary: [
    { icon: faClipboardList, label: 'N° Lezioni:', value: '160+ video' },
    { icon: faClock, label: 'Corso Angular:', value: '18+ ore' },
    { icon: faClock, label: 'Corso Javascript ES6:', value: '2 ore' },
    { icon: faClock, label: 'Corso Librerie NPM:', value: '1 ora' },
    { icon: faChartBar, label: 'Livello:', value: 'Principiante / Intermedio' },
    { icon: faCode, label: 'Versione Angular:', value: '8.x' },
    { icon: faVideo, label: 'Tipologia:', value: 'Video Corso' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questa breve lezione andremo ad imparare:\n',
    video1: '_Ya2cAYuFII',
    items: [
      { text: 'L’utilizzo della CLI (Command Line Interface)'},
      { text: 'La generazione di componenti e moduli custom'},
      { text: 'Approccio component-based'},
      { text: 'Organizzazione progetti modulari'},
      { text: 'Creazione di \'features\' custom module'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'SINGOLI ESEMPI',
      text: 'Decine di esempi pratici e casi d’uso sulle principali funzionalità del framework, le API più popolari e le best practice più utili per la creazione di codice riutilizzabile e scalabile.',
    },
    colCenter: {
      img: 'mode2',
      title: 'TEORIA',
      text: 'Video lezioni dedicate alla teoria in cui saranno descritti i concetti tramite l’utilizzo di slide, diagrammi animati e materiale esclusivo.\n',
    },
    colRight: {
      img: 'mode3',
      title: 'REAL APPS',
      text: 'Sessioni di live coding in cui saranno sviluppate alcune Single Page Application che comunicano con il server tramite REST API e una RealTime Application che utilizza Google Firebase per la sincronizzazione realtime tra diversi client.\n'
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'Panoramica' },
      { value: 'Introduzione al corso (4:44)' },
      { value: 'Gli argomenti del corso in dettaglio (5:03)' },
      { value: 'Single Page Applications e approccio component-based (14:37)' },
      { value: 'Angular Framework: le funzionalità principali (17:17)' },
      { type: 'title', value: 'Installazione e configurazione' },
      { value: 'Installazione e utilizzo di NodeJS e NVM (4:16)' },
      { value: 'Installare Angular CLI e creare un progetto Angular (2:34) ' },
      { value: 'Introduzione al boilerplate (2:05)' },
      { value: 'AppComponent e AppModule: parte 1 - intro (3:50) ' },
      { value: 'AppComponent e AppModule: parte 2 - code (3:44)' },
      { value: 'ng new: parametri (1:59)' },
      { type: 'title', value: 'Fundamentals' },
      { value: 'Interpolation {{value}} (1:10)' },
      { value: 'Native events: mouse e tastiera (5:48) ' },
      { value: 'Directives (5:19)' },
      { value: 'Attributi e parentesi quadre (brackets) (6:29)' },
      { value: 'Components (5:07)' },
      { value: 'Pipes (5:17)' },
      { value: 'Custom Types (5:46)' },
      { value: 'Introduzione ai service e alla dependency injection (3:52)' },
      { value: 'Comunicazione con server, REST API e HttpClient (5:03)' },

      { type: 'title', value: 'Angular Structural Directives' },
      { value: 'La direttiva ngIf e l\'opzione ngIf...else (6:02)' },
      { value: 'La direttiva ngFor: introduzione e tipizzare collezioni (6:59)' },
      { value: 'La direttiva ngFor: proprietà index, last, odd (3:30)' },
      { value: 'La direttiva ngFor: manipolazione dati (5:58)' },
      { value: 'La direttiva ngSwitch (4:53)' },

      { type: 'title', value: 'Styling' },
      { value: 'Styles and Components (4:06) ' },
      { value: 'Inline CSS class (2:08)' },
      { value: 'La direttiva ngClass (3:15)' },
      { value: 'Inline Styling (3:27)' },
      { value: 'La direttiva ngStyle (1:50)' },
      { value: 'Librerie CSS 3rd party e angular.json (6:12)' },

      { type: 'title', value: 'Template-driven Form' },
      { value: 'Input, "template reference variables", keyboard events (7:25)' },
      { value: 'La direttiva ngModel: 1way vs 2 way binding (5:49)' },
      { value: 'ngForm, ngModel e gestione data model (13:50)' },
      { value: 'Form Validation (8:19)' },
      { value: 'Form, validazione e gestione errori (4:09)' },

      { type: 'title',value: 'Custom components' },
      { value: 'Hello Components (5:52)' },
      { value: 'Input Properties (2:32)' },
      { value: 'Content Projection (7:35)' },
      { value: 'Componenti "stateful" e Input default value (2:32)' },
      { value: '@Output event emitter: realizzare un TabBar component riutilizzabile (24:10)' },
      { value: 'Lifecycle hook: ngOnInit (9:23)' },
      { value: 'Lifecycle Hook: onChange (5:20)' },
      { value: 'ChangeDetectionStrategy, ChangeDetectorRef e stato immutabile: ottimizzazione performance e controllo del rendering (14:45)' },

      { type: 'title', value: 'BrowserAnimationModule: animazioni in Angular' },
      { value: 'Animated Collapsable Panel: trigger, style, state e animate (11:37)' },
      { value: 'Animated TabBar: animations e ciclo di vita dei componenti (15:58)' },
      { value: 'Animated Text: gestire gli eventi delle animation (14:02)' },

      { type: 'title', value: 'Multi-view applications con Angular Router' },
      { value: 'Introduzione ad angular router (2:03)' },
      { value: 'Utilizzo di Angular Router e creazione componenti con Angular CLI (6:15)' },
      { value: 'Navigation Bar (5:33)' },
      { value: 'AngularCLI ei il modulo AppRoutingModule (5:13)' },
      { value: 'Passaggio parametri e ActivatedRoute (8:30)' },
      { value: 'ActivatedRoute e Router API (6:23)' },
      { value: 'Eventi del router & RxJS operators (4:50)' },

      { type: 'title', value: 'Comunicazione con il server' },
      { value: 'Configurare un mock server: json-server (5:13)' },
      { value: 'GET: Caricamento dati da REST API e custom types (9:30) ' },
      { value: 'DELETE: Cancellazione elementi e gestione errori XHR (8:39)' },
      { value: 'Dynamic styles e migliorare il look & feel (7:45)' },
      { value: 'POST: Aggiungere elementi alla collezione tramite form (9:55)' },
      { value: 'PUT e PATCH: aggiornare un elemento (13:34)' },
    ],
    colRight: [



      { type: 'title', value: 'Dependency Injection' },
      { value: 'Dependency Injection, injector e providers (6:01) ' },
      { value: 'Condividere dati utilizzando i "Service" (8:43)' },
      { value: 'Service, REST API e decoratore @injectable (10:57)' },
      { value: 'Gestire lo stato applicativo (5:49)' },

      { type: 'title', value: 'Applicazioni modulari, approfondimento router e lazy loading' },
      { value: 'Moduli: introduzione (8:23)' },
      { value: 'Applicazioni Modulari (5:38)' },
      { value: 'es6 modules vs ngModules (2:21)' },
      { value: 'Feature Modules (10:12)' },
      { value: 'Shared Modules (11:25)' },
      { value: 'Core Module (3:56)' },
      { value: 'Router & Lazy Loading (11:56)' },
      { value: 'Moduli e route di secondo livello (5:54)' },
      { value: 'Router children e router-outlet secondario (5:34)' },
      { value: 'Angular 8, lazy loading & imports (3:41)' },

      { type: 'title', value: 'Build & Deploy' },
      { value: 'Creare la build del progetto e installare un webserver locale (3:09)' },
      { value: 'Ottimizzazione build, production e tree shaking (2:41)' },
      { value: 'Deploy delle applicazioni su Surge.sh (2:37)' },
      { value: 'Pubblicare un\'applicazione Angular su Surge.sh (0:54)' },
      { value: 'environment variables: production vs development (5:44)' },

      { type: 'title',  value: 'Live Coding / SPA: Hotel-Booking App' },
      { value: 'Video demo dell\'applicazione (0:20)' },
      { value: 'PARTE 1: SVILUPPO APPLICAZIONE' },
      { value: 'Layout statico HTML con CSS Grid (+ source code) (8:37)' },
      { value: 'Creazione progetto, gestione router e impaginazione layout (11:36)' },
      { value: 'Server REST, comunicazione server, model, gestione form e visualizzazione risultati (23:21)' },
      { value: 'Card component e pipe: image gallery, forms, maps, rate, ... (34:35)' },
      { value: 'Gestione del carrello (19:26)' },
      { value: 'Autenticazione, "sicurezza" router utilizzando le guardie e protezione DOM (25:59) PARTE 2: APPROCCIO COMPONENT-BASED' },
      { value: 'Approccio component-based - PART 1: hotel-form, hotel-list e shared components (16:42)' },
      { value: 'Approccio component-based - PART 2: i componenti rimanenti (16:57)' },
      { value: 'Approccio component-based - PART 3: card component flessibile e riutilizzabile (HostBinding) (17:02)' },
      { value: 'Gestire il caso in cui il risultato della ricerca non produca risultati (4:08) PARTE 3: MODULI e LAZY LOADING' },
      { value: 'Shared Module (5:57)' },
      { value: 'Core Module (5:34)' },
      { value: 'Features Modules (6:19) Router & Lazy Loading (21:06)' },

      { type: 'title',  value: 'Live Coding / Crud APP: approccio component-based, gestione stato & Best Practice' },
      { value: 'Demo dell\'applicazione (0:11)' },
      { value: 'Creazione progetto, TSLint, installazione dipendenze e Mock Server (9:46)' },
      { value: 'Creare "features components" da Angular-CLI e gestione router (4:04)' },
      { value: 'GET e DELETE: visualizzazione e cancellazione dati dal server (7:40)' },
      { value: 'PUT, PATCH e POST: modifica e inserimento dati (17:34)' },
      { value: 'Organizzare il layout in componenti: CatalogList (8:05)' },
      { value: 'Organizzare il layout in componenti: CatalogForm (14:20)' },
      { value: 'Metodo onChanges del ciclo di vita dei componenti e decoratore @ViewChild (9:17)' },
      { value: 'Organizzazione dell\'applicazione in services (5:36)' },
      { value: 'Gestire lo stato applicativo (8:18)' },
      { value: 'Nested Components: componenti dentro componenti (5:12)' },
      { value: 'Shared components: componenti riutilizzabili (9:35)' },
      { value: 'Core Components: Navigation bar e router (4:17)' },

      { type: 'title',  value: 'Live Coding / REALTIME APP con Firebase : creare un remote controller per controllare un viewer di slides' },
      { value: 'Demo dell\'applicazione "remote controller" (0:18)' },
      { value: 'Creazione progetto con router (3:24)' },
      { value: 'ADMIN: Creazione layout statico - HTML + CSS (16:21)' },
      { value: 'VIEWER: Creazione layout statico - HTML + CSS (1:48) ' },
      { value: 'Introduzione a Firebase (3:22)' },
      { value: 'AngularFire: installazione e configurazione (4:09)' },
      { value: 'CONTROLLER: Creazione della galleria immagini realtime in Firebase (19:31)' },
      { value: 'CONTROLLER: controllare le slide (7:24)' },
      { value: 'VIEWER: visualizzazione delle immagini realtime (3:05)' },
      { value: 'VIEWER: bug fix (9:29)' },
      { value: 'VIEWER: aggiungere le animazioni (8:44)' },
      { value: 'VIEWER: animation bug fix' },
      { value: 'Testare l\'applicazione da un dispositivo mobile tramite rete locale durante lo sviluppo: ng serve --host (1:19)' },
      { type: 'title', value: 'EXTRA:' },
      { value: 'StackBlitz: editor online per condividere snippet (3:34)' },
      { value: 'Introduzione ai Reactive Forms - Codemotion 2019 (40:53)' },
      { value: 'Altri video in arrivo....' },
    ],
  },
  pricing: {
    price: '205+iva',
    priceOffer: null,
    priceOfferDescription: 'Offerta valida fino al 31 agosto 2019',
    items: [
      { label: 'Video corso Angular Fundamentals', extra: '18+ ore'},
      { label: 'Mini Corso JAVASCRIPT ES6', extra: '2 ore'},
      { label: 'Mini Corso Creazione librerie NPM in Angular', extra: '1 ora'},
      { label: 'Piattaforma Corsi Professionale', extra: 'OnLine'},
      { label: 'Aggiornamenti futuri', extra: 'Inclusi'},
      { label: 'Supporto Tecnico', extra: 'Gruppo Facebook'},
      { label: 'CheatSheet Angular', extra: 'PDF formato A4'},
      { label: 'CheatSheet Javascript ES6', extra: 'PDF formato A4'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Soddisfatto o Rimborsato', extra: 'Entro 30gg'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyText: 'ACQUISTA IL  CORSO',
      buyLink: 'https://sso.teachable.com/secure/213789/checkout/965155/bundle-angular-7-fundamentals',
      scrollToID: '#newsletter'
    },
    bg: null
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyText: 'ACQUISTA IL CORSO',
      buyLink: null,
      target: '_scrollTo',
      scrollToID: '#pricingPanel'
    }
  },
  faq: {
    items: [
      { title: 'Sono indeciso sull\'acquisto, a chi posso chiedere consiglio?', desc: 'Abbiamo creato un gruppo Facebook dedicato al corso <a href="https://www.facebook.com/groups/angular.fundamentals/?ref=bookmarks" target="_blank">Angular Fundamentals</a> in cui potrai fare domande agli altri partecipanti, chiedere feedback e consigli'},
      { title: 'Posso essere rimborsato?', desc: 'Assolutamente si, entro 30 giorni dall\'acquisto nel caso il corso non soddisti le tue aspettative. Il rimborso sarà effettuato entro un paio di giorni dalla richiesta'},
      { title: 'Come posso visualizzare le lezioni?', desc: 'Dopo l\'acquisto ti sarà inviata una email per accedere ad una piattaforma online per la visualizzazione dei corsi'},
      { title: 'Ho bisogno di supporto tecnico?', desc: 'Tramite gruppo Facebook dedicato al supporto tecnico: <a href="https://www.facebook.com/groups/angular.fundamentals/?ref=bookmarks" target="_blank">Angular Fundamentals</a> '},
      { title: 'Il corso sarà aggiornato?', desc: 'Si, vengono spesso inseriti aggiornamenti e nuovi contenuti'},
      { title: 'Dopo aver pagato, per quanto tempo posso usufruire del corso?', desc: 'Non possiamo garantire un accesso a vita ma sicuramente per diversi anni'},
      { title: 'Posso ricevere la fattura per l\'acquisto? Pagherò l\'IVA?', desc: 'Il sito è distribuito su piattaforma americana, quindi, se acquisti come privato l\'iva sarà aggiunta al totale in fase di acquisto. Al contrario, se acquisti con partita iva, l\'iva non sarà dovuta e ti sarà inviata una ricevuta di acquisto che conterrà solo nome e partita iva. <a href="http://assets.fabiobiondi.io/website-training/receipt-teachable-2019.png" target="_blank">Vedi un esempio di ricevuta</a>. '},
      { title: 'Ci sono sconti nel caso di acquisti multipli, per team e aziende?', desc: 'Contattami per un offerta personalizzata attraverso <a href="../forms/contact-form/?subject=4">form di contatto</a> specificando la quantità di licenze che sei interessato ad acquistare'},
      { title: 'Voglio lasciarti una recensione. Dove posso farlo?', desc: 'Nel gruppo Facebook dedicato al corso <a href="https://www.facebook.com/groups/angular.fundamentals/?ref=bookmarks" target="_blank">Angular Fundamentals</a>, nella sezione "Recensioni" della mia  <a href="https://www.facebook.com/pg/biondifabio/reviews/" target="_blank">pagina Facebook</a> o nella sezione "recommendations" del mio <a href="https://www.linkedin.com/in/fabiobiondi/" target="_blank">profilo LinkedIn</a>'},
      { title: 'Sei disponibile per erogare dei corsi di approfondimento presso la nostra sede?', desc: 'Assolutamente sì. Scrivimi attraverso il <a href="../forms/contact-form/?subject=1">form di contatto</a>'},
    ]
  },

}
